import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  // scanresult
  {
    path: "/scanresult",
    name: "scanresult",
    component: () => import("../views/scanresult.vue"),
  },
  {
    path: "/section/content/:id",
    name: "classFile",
    component: () => import("../views/ClassFile.vue"),
  },
  {
    path: "/crmPay",
    name: "crmPay",
    component: () => import("../views/crmPay.vue"),
  },
  {
    path: "/crmShare",
    name: "crmShare",
    component: () => import("../views/crmShare.vue"),
  },
  {
    path: "/coupon",
    name: "coupon",
    component: () => import("../views/coupon.vue"),
  },
  {
    path: "/payend",
    name: "payend",
    meta: {
      title: "购买完成",
      isFinite: true,
    },
    component: () => import("../views/payend.vue"),
  },
  {
    path: "/jPush",
    name: "极光推送",
    component: () => import("../views/JPush.vue"),
  },
  {
    path: "/FailedToAppStore",
    name: "AppStore",
    component: () => import("../views/FailedToAppStore.vue"),
  },
  {
    path: "/test",
    name: "test",
    component: () => import("../views/test.vue"),
  },
  {
    path: "/live",
    name: "ShareLive",
    component: () => import("../views/ShareLive.vue"),
  },
  {
    path: "/article/special/:id",
    name: "ShareArticle",
    component: () => import("../views/ShareArticle.vue"),
  },
  {
    path: "/thoughts/:id",
    name: "thoughts",
    component: () => import("../views/ShareThoughts.vue"),
  },
  {
    path: "/pdfdetail/:id",
    name: "pdfdetail",
    component: () => import("../views/pdfdetail.vue"),
  },
  {
    path: "/section/:id",
    name: "SharingLessons",
    component: () => import("../views/SharingLessons.vue"),
  },
  {
    path: "/voice/:id",
    name: "ShareVoice",
    component: () => import("../views/ShareVoice.vue"),
  },
  {
    path: "/share",
    name: "ShareApp",
    component: () => import("../views/ShareApp.vue"),
  },
  //协议
  {
    path: "/agreement/registration",
    name: "registration",
    component: () => import("../views/registration.vue"),
  },
  //个股详情预览pdf
  {
    path: "/reportPdf/:id",
    name: "reportPdf",
    component: () => import("../views/reportPdf.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
// router.beforeEach((to, from, next) => {
//   //beforeEach是router的钩子函数，在进入路由前执行
//   if (to.meta.isFinite) {
//     //判断是否有标题
//     console.log(to.meta.title);
//     document.title = "购买完成";
//   }
//   console.log("444444", to.meta.isFinite);
//   next();
// });
export default router;
